import { IoLocationOutline, IoResize } from "react-icons/io5";
import { FaEuroSign, FaUsers } from "react-icons/fa";
import { FaLaptopHouse } from "react-icons/fa";
import { Gi3dStairs } from "react-icons/gi";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetAdminAllhousingByIdQuery,
  useUpdateHousingStatusByIdMutation,
} from "../../../../app/features/housing/housingApi";
import { useGetAllCityQuery } from "../../../../app/features/account/accountApi";
import toast from "react-hot-toast";
import LoadingUi from "../../../../components/LoadingUi/LoadingUi";
import SEOData from "../../../../components/SEO/SEO";

const AllHousingById = () => {
  const params = useParams();
  const navigate = useNavigate();
  // console.log(params);

  const {
    data: housingById,
    error: housingByIdError,
    loading: housingByIdLoading,
  } = useGetAdminAllhousingByIdQuery(params?.id);
  const {
    data: cityData,
    error: cityDataError,
    loading: cityDataLoading,
  } = useGetAllCityQuery();

  const [updateHousingStatusById] = useUpdateHousingStatusByIdMutation();

  // console.log(housingById);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const getCityName = (cityId) => {
    const city = cityData?.results.find((city) => city.id === cityId);
    return city?.name;
  };

  const handleStatusApproved = async () => {
    const data = {
      status: "approved",
    };
    const toastId = toast.loading("Changing Status. Please wait...");
    try {
      const res = await updateHousingStatusById({
        id: housingById?.id,
        data: data,
      });
      toast.success("Housing Request Approved.", {
        id: toastId,
        duration: 4000,
      });
      navigate("/admin/dashboard/housing");
    } catch (error) {
      if (error?.name === "SyntaxError") {
        toast.error(
          "We encountered a technical issue. Please clear your browser cookies and try again. If the issue persists, reach out to support for assistance.",
          { id: toastId, duration: 5000 }
        );
      } else if (error?.status === "FETCH_ERROR") {
        toast.error(
          "Network Error: Unable to connect to the server. Please check your internet connection or try again later.",
          { id: toastId, duration: 5000 }
        );
      } else {
        toast.error(
          "We encountered a server issue. Please try again after sometime!!!",
          {
            id: toastId,
            duration: 5000,
          }
        );
      }
    }
  };

  const handleStatusReject = async () => {
    const data = {
      status: "rejected",
    };
    const toastId = toast.loading("Changing Status. Please wait...");
    try {
      const res = await updateHousingStatusById({
        id: housingById?.id,
        data: data,
      });
      toast.success("Housing Request rejected ", {
        id: toastId,
        duration: 4000,
      });
      navigate("/admin/dashboard/housing");
    } catch (error) {
      if (error?.name === "SyntaxError") {
        toast.error(
          "We encountered a technical issue. Please clear your browser cookies and try again. If the issue persists, reach out to support for assistance.",
          { id: toastId, duration: 5000 }
        );
      } else if (error?.status === "FETCH_ERROR") {
        toast.error(
          "Network Error: Unable to connect to the server. Please check your internet connection or try again later.",
          { id: toastId, duration: 5000 }
        );
      } else {
        toast.error(
          "We encountered a server issue. Please try again after sometime!!!",
          {
            id: toastId,
            duration: 5000,
          }
        );
      }
    }
  };

  if (housingByIdLoading && cityDataLoading) {
    return <LoadingUi />;
  }

  return (
    <div>
      <SEOData
        title={`Dashboard || Housing Details`}
        description={`Dashboard Housing Details page.`}
        canonical={window.location.pathname}
      />
      <div className="flex flex-col lg:flex-row items-center lg:items-start lg:space-x-8 p-6 bg-white z-10 h-screen">
        {/* Image Section */}
        <div className="w-full  flex-2">
          <Swiper
            navigation={true}
            pagination={{ clickable: true }}
            modules={[Navigation, Pagination]}
            className="mySwipe max-w-[620px]"
          >
            {housingById?.images.map((houseImg, index) => (
              <SwiperSlide key={index}>
                <img
                  src={houseImg?.image}
                  alt={`Product Image ${index + 1}`}
                  className="w-full h-[450px] object-cover rounded-xl"
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <div>
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{ __html: housingById?.description }}
            />
          </div>
        </div>

        {/* Details Section */}
        <div className="w-full   mt-6 lg:mt-0 space-y-5 flex-1">
          <h1 className="text-3xl font-bold mb-4 text-[#002663] ">
            {housingById?.name}
          </h1>
          <p className="">
            <span className=" font-medium flex items-center  gap-1  mt-1 text-[#002663]">
              <span className="text-3xl  text-btnColor-400   mr-2">
                <IoLocationOutline />
              </span>
              {getCityName(housingById?.city)}
            </span>
          </p>

          <p className="">
            <span className=" font-medium flex items-center  gap-1  mt-1 text-[#002663]">
              <span className="text-3xl  text-btnColor-400  mr-2">
                <FaLaptopHouse />
              </span>
              {housingById?.types}
            </span>
          </p>
          <p className="">
            <span className=" font-medium flex items-center  gap-1  mt-1 text-[#002663]">
              <span className="text-3xl  text-btnColor-400  mr-2">
                <FaEuroSign />
              </span>
              {housingById?.details?.rent_per_month} &euro;/month
            </span>
          </p>
          <p className="">
            <span className=" font-medium flex items-center  gap-1  mt-1 text-[#002663]">
              <span className="text-3xl  text-btnColor-400  mr-2">
                <Gi3dStairs />
              </span>
              {housingById?.details?.floor} floor
            </span>
          </p>
          <p className="">
            <span className=" font-medium flex items-center  gap-1  mt-1 text-[#002663]">
              <span className="text-3xl  text-btnColor-400  mr-2">
                <FaUsers />
              </span>
              <span className="text-xl font-semibold text-red-400">
                {housingById?.details?.allowed_person}
              </span>{" "}
              Allow Persons
            </span>
          </p>
          <p className="">
            <span className=" font-medium flex items-center  gap-1  mt-1 text-[#002663]">
              <span className="text-3xl  text-btnColor-400  mr-2">
                <IoResize />
              </span>
              {housingById?.apartment_size}
            </span>
          </p>

          <div className="mb-6">
            <h2 className="text-2xl font-semibold mb-2 text-[#002663]">
              Features
            </h2>
            <ul className="list-disc list-inside ">
              {housingById?.features.map((feature, index) => (
                <li key={index} className="  text-btnColor-400  mr-2">
                  {" "}
                  <span className=" text-[#002663] font-medium ">
                    {feature?.name}
                  </span>
                </li>
              ))}
            </ul>
          </div>

          <div className="mb-1">
            <h2 className="text-2xl font-semibold mb-2 text-[#002663]">
              Nearest University Info
            </h2>

            <div>
              <h1 className="text-lg font-medium text-[#002663]">
                {" "}
                Name : <span>{housingById?.nearest[0].name}</span>
              </h1>
              <h1 className="text-base font-medium text-[#002663]">
                {" "}
                Distance :{" "}
                <span className="text-red-600">
                  {housingById?.nearest[0].distance}
                </span>
              </h1>
            </div>
          </div>

          <div className="p-6 bg-white shadow-md rounded-lg max-w-sm lg:mx-auto">
            <h2 className="text-2xl font-semibold text-btnColor-400 mb-4 text-center">
              Booking Details
            </h2>

            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <span className="text-green-600 font-medium">
                  Booking Date:
                </span>
                <span className="text-gray-900 font-bold">
                  {formatDate(housingById?.details?.rent_start_date)}
                </span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-red-600 font-medium">Ending Date:</span>
                <span className="text-gray-900 font-bold">
                  {formatDate(housingById?.details?.rent_end_date)}
                </span>
              </div>
            </div>
          </div>

          <div className="p-6 bg-white shadow-md rounded-lg max-w-sm lg:mx-auto">
            <h2 className="text-2xl font-semibold text-btnColor-400 mb-4 text-center">
              Contact Information
            </h2>

            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <span className="text-green-600 font-medium">Email :</span>
                <span className="text-gray-900 font-bold">
                  {housingById?.email}
                </span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-green-600 font-medium">
                  Phone Number :
                </span>
                <span className="text-gray-900 font-bold">
                  {housingById?.phone}
                </span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-green-600 font-medium">
                  WhatsApp Number :
                </span>
                {/* <span className="text-gray-900 font-bold">
                  {housingById?.whatsapp}
                </span> */}
                {housingById?.whatsapp && (
                  <span className="text-gray-900 font-bold">
                    <a
                      href={`https://wa.me/${housingById?.whatsapp}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-900 font-bold"
                    >
                      {housingById?.whatsapp}
                    </a>
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center pt-8">
            <button
              className="bg-gradient-to-r bg-green-700 block w-[150px] py-2 mx-4 mb-5 rounded text-white font-semibold"
              onClick={handleStatusApproved}
            >
              Approved
            </button>
            <button
              className="bg-gradient-to-r bg-red-600 block w-[150px] py-2 mx-4 mb-5 rounded-lg text-white font-semibold"
              onClick={handleStatusReject}
            >
              Rejects
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllHousingById;
