/* eslint-disable react/no-unknown-property */
import { IoLocationOutline, IoResize } from "react-icons/io5";
import { FaEuroSign, FaUsers } from "react-icons/fa";
import { FaLaptopHouse } from "react-icons/fa";
import { Gi3dStairs } from "react-icons/gi";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { useGetAllhousingCustomerByIdQuery } from "../../../app/features/housing/housingApi";
import {
  useGetAllCityQuery,
  useGetCountryQuery,
} from "../../../app/features/account/accountApi";
import { useParams } from "react-router-dom";
import LoadingUi from "../../../components/LoadingUi/LoadingUi";
import CryptoJS from "crypto-js";
import config from "../../../config";
import SocialShare from "../../../components/SocialShare/SocialShare";
import SocialMeta from "../../../components/SocialShare/SocialMeta";
import image from "../../../assets/SocialShare/crop.png";
import SEOData from "../../../components/SEO/SEO";
import seoImage from "../../../assets/SocialShare/crop.png";

const HousingDetailsPage = () => {
  const params = useParams();
  // console.log(params);

  const decodeId = (hashedId) => {
    try {
      const bytes = CryptoJS.AES.decrypt(
        decodeURIComponent(hashedId),
        config.secretKey
      );
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      // console.error("Failed to decode ID:", error);
      return null;
    }
  };

  const decodedId = decodeId(params?.id);

  const {
    data: housing,
    loading: housingLoading,
    error: housingError,
  } = useGetAllhousingCustomerByIdQuery(decodedId);
  // console.log(housing);

  const {
    data: cityData,
    error: cityError,
    loading: cityLoading,
  } = useGetAllCityQuery();
  const {
    data: countryData,
    error: countryError,
    loading: countryLoading,
  } = useGetCountryQuery();

  const getCityName = (cityId) => {
    const city = cityData?.results.find((city) => city.id === cityId);
    return city?.name;
  };

  const getCountryName = (cityId) => {
    const city = countryData?.results.find((city) => city.id === cityId);
    return city?.name;
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (housingLoading && cityLoading) {
    return <LoadingUi />;
  }

  <style jsx>{`
    .swiper-button-next,
    .swiper-button-prev {
      @apply w-6 h-6 bg-gray-800 text-white rounded-full flex items-center justify-center;
      font-size: 12px;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
      font-size: 12px;
    }
  `}</style>;

  return (
    <>
      <div
        className="max-w-[1200px] mx-auto flex flex-col lg:flex-row items-center lg:items-start lg:space-x-8 py-8
      bg-white -z-10"
      >
        {housing && (
          <SEOData
            title={`UniEdu || ${housing?.name}`}
            description={`UniEdu ${housing?.name} - ${housing?.location} details`}
            canonical={window.location.href}
            image={
              housing?.images[0]?.image
                ? housing?.images[0]?.image
                : `https://uniedu.fi${seoImage}`
            }
          />
        )}

        {/* Image Section */}
        <div className="w-full lg:w-2/3">
          <Swiper
            navigation={true}
            pagination={{ clickable: true }}
            modules={[Navigation, Pagination]}
            className="mySwiper"
          >
            {housing?.images.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image?.image}
                  alt={`Product Image ${index + 1}`}
                  className="w-full h-[450px] object-cover rounded-xl"
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <div>
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{ __html: housing?.description }}
            />
          </div>
        </div>

        {/* Details Section */}
        <div className="w-full lg:w-1/3 mt-6 lg:mt-0 space-y-5">
          <h1 className="text-3xl font-bold mb-4 text-[#002663] ">
            {housing?.name}
          </h1>
          <p className="">
            <span className=" font-medium flex items-center  gap-1  mt-1 text-[#002663]">
              <span className="text-3xl  text-btnColor-400  mr-2">
                <IoLocationOutline />
              </span>
              {housing?.location} {getCityName(housing?.city)}{" "}
              {getCountryName(housing?.country)}
            </span>
          </p>

          <p className="">
            <span className=" font-medium flex items-center  gap-1  mt-1 text-[#002663] capitalize">
              <span className="text-3xl  text-btnColor-400 mr-2">
                <FaLaptopHouse />
              </span>
              {housing?.types} Appartment, {housing?.details?.rooms}H
              {housing?.details?.bathrooms}B{housing?.details?.kitchen}K
            </span>
          </p>
          <p className="">
            <span className=" font-medium flex items-center  gap-1  mt-1 text-[#002663]">
              <span className="text-3xl  text-btnColor-400 mr-2">
                <FaEuroSign />
              </span>
               {housing?.details?.rent_per_month} &euro;/month
            </span>
          </p>
          <p className="">
            <span className=" font-medium flex items-center  gap-1  mt-1 text-[#002663]">
              <span className="text-3xl  text-btnColor-400 mr-2">
                <Gi3dStairs />
              </span>
              {housing?.details?.floor} floor
            </span>
          </p>
          <p className="">
            <span className=" font-medium flex items-center  gap-1  mt-1 text-[#002663]">
              <span className="text-3xl  text-btnColor-400 mr-2">
                <IoResize />
              </span>
              {housing?.apartment_size} m&sup2;
            </span>
          </p>
          <p className="">
            <span className=" font-medium flex items-center  gap-1  mt-1 text-[#002663]">
              <span className="text-3xl  text-btnColor-400  mr-2">
                <FaUsers />
              </span>
              <span className="text-xl font-semibold text-red-400">
                {housing?.details?.allowed_person}
              </span>{" "}
              Allow Persons
            </span>
          </p>

          <div className="mb-6">
            <h2 className="text-2xl font-semibold mb-2 text-[#002663]">
              Features
            </h2>
            <ul className="list-disc list-inside ">
              {housing?.features.map((amenity, index) => (
                <li key={index} className="  text-btnColor-400 mr-2">
                  {" "}
                  <span className=" text-[#002663] font-medium ">
                    {amenity?.name}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="mb-1">
            <h2 className="text-2xl font-semibold mb-2 text-[#002663]">
              Nearest University Info
            </h2>

            <div>
              <h1 className="text-lg font-medium text-[#002663]">
                {" "}
                Name : <span>{housing?.nearest[0].name}</span>
              </h1>
              <h1 className="text-base font-medium text-[#002663]">
                {" "}
                Distance :{" "}
                <span className="text-red-600">
                  {housing?.nearest[0].distance}
                </span>
              </h1>
            </div>
          </div>

          <div className="p-6 bg-white shadow-md rounded-lg max-w-sm lg:mx-auto">
            <h2 className="text-2xl font-semibold text-btnColor-400 mb-4 text-center">
              Booking Details
            </h2>

            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <span className="text-green-600 font-medium">
                  Booking Date:
                </span>
                <span className="text-gray-900 font-bold">
                  {formatDate(housing?.details?.rent_start_date)}
                </span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-red-600 font-medium">Ending Date:</span>
                <span className="text-gray-900 font-bold">
                  {formatDate(housing?.details?.rent_end_date)}
                </span>
              </div>
            </div>
          </div>

          <div className="p-6 bg-white shadow-md rounded-lg max-w-sm lg:mx-auto">
            <h2 className="text-2xl font-semibold text-btnColor-400 mb-4 text-center">
              Contact Information
            </h2>

            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <span className="text-green-600 font-medium">Email :</span>
                <span className="text-gray-900 font-bold">
                  {housing?.email}
                </span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-green-600 font-medium">
                  Phone Number :
                </span>
                <span className="text-gray-900 font-bold">
                  {housing?.phone}
                </span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-green-600 font-medium">
                  WhatsApp Number :
                </span>
                {/* <span className="text-gray-900 font-bold">
                    {housing?.whatsapp}
                  </span> */}
                {housing?.whatsapp && (
                  <span className="text-gray-900 font-bold">
                    <a
                      href={`https://wa.me/${housing?.whatsapp}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-900 font-bold"
                    >
                      {housing?.whatsapp}
                    </a>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HousingDetailsPage;
