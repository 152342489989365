import { useEffect, useState } from "react";
import {
  useGetMyHousingRequestQuery,
  useUpdateContactHousingAdminByIdMutation,
} from "../../../app/features/housing/housingApi";
import {
  useGetAllCityQuery,
  useGetCountryQuery,
} from "../../../app/features/account/accountApi";
import toast from "react-hot-toast";
import SEOData from "../../../components/SEO/SEO";

const HousingFormAdmin = () => {
  const [selectedUser, setSelectedUser] = useState(null); // State to store selected user data
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [status, setStatus] = useState("");
  useEffect(() => {
    if (selectedUser && selectedUser?.status) {
      setStatus(selectedUser?.status);
    }
  }, [selectedUser]);

  const handleCardClick = (apartment) => {
    // console.log(apartment);

    setSelectedUser(apartment);
    setIsModalOpen(true); // Open modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close modal
    if (selectedUser && selectedUser?.status) {
      setStatus(selectedUser?.status);
    }
  };
  const {
    data: housingReq,
    loading: housingReqLoading,
    refetch: housingReqRefetch,
    error: housingReqError,
  } = useGetMyHousingRequestQuery();
  const {
    data: countryData,
    error: countryError,
    loading: countryLoading,
  } = useGetCountryQuery();

  const {
    data: cityData,
    error: cityDataError,
    loading: cityDataLoading,
  } = useGetAllCityQuery();

  const getCountryName = (cityId) => {
    const city = countryData?.results.find((city) => city.id === cityId);
    return city?.name;
  };
  const getCityName = (cityId) => {
    const city = cityData?.results.find((city) => city.id === cityId);
    return city?.name;
  };

  // console.log(status);

  // console.log(housingReq?.results);
  const [updateContactHousingAdminById] =
    useUpdateContactHousingAdminByIdMutation();

  const handleStatusSubmit = async () => {
    const data = {
      status: status,
    };
    const toastId = toast.loading("Updating Status. Please wait...");
    try {
      const res = await updateContactHousingAdminById({
        id: selectedUser?.id,
        data,
      }).unwrap();

      if (res?.id) {
        toast.success("Update Status Successfully.", { id: toastId });
        setIsModalOpen(false);
        housingReqRefetch();
      }
    } catch (error) {
      // console.log(error);
      if (error?.status === 403 && error?.data?.errors?.detail) {
        toast.error(error?.data?.errors?.detail, {
          id: toastId,
          duration: 5000,
        });
      } else if (error?.name === "SyntaxError") {
        toast.error(
          "We encountered a technical issue. Please clear your browser cookies and try again. If the issue persists, reach out to support for assistance.",
          { id: toastId, duration: 5000 }
        );
      } else if (error?.status === "FETCH_ERROR") {
        toast.error(
          "Network Error: Unable to connect to the server. Please check your internet connection or try again later.",
          { id: toastId, duration: 5000 }
        );
      } else {
        toast.error(
          "We encountered a server issue. Please try again after sometime!!!",
          {
            id: toastId,
            duration: 5000,
          }
        );
      }
    }
  };

  useEffect(() => {
    housingReqRefetch();
  }, [isModalOpen, housingReqRefetch]);

  return (
    <div className="bg-slate-100 w-full h-full">
      <SEOData
        title={`Dashboard || Find Housing Request`}
        description={`Dashboard Find Housing Request page.`}
        canonical={window.location.pathname}
      />
      <div className="py-8 px-5">
        <h3 className="text-2xl font-semibold">Find Housing Request</h3>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-blue-100">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider">
                Accommodation
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider">
                City
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider">
                Gender
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider">
                Single / Couple
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {housingReq?.results?.map((apartment) => (
              <tr
                key={apartment?.id}
                className="border-b cursor-pointer hover:bg-gray-300"
                onClick={() => handleCardClick(apartment)}
              >
                {/* <td className="px-6 py-4">
                        <img
                          src={apartment.images[0]?.image}
                          alt={apartment.name}
                          className="w-16 h-16 object-cover rounded-md"
                        />
                      </td> */}
                <td className="px-6 py-4 text-gray-700">
                  {apartment.name.split(" ")[0]}
                </td>
                <td className="px-6 py-4 text-gray-700 capitalize">
                  {apartment?.accommodation}
                </td>
                {/* <td className="px-6 py-4 text-gray-700">{apartment.rooms}</td> */}
                <td className="px-6 py-4 text-gray-700">
                  {" "}
                  {getCityName(apartment?.city)}{" "}
                </td>
                <td className="px-6 py-4 text-gray-700 capitalize">
                  {apartment.gender}
                </td>
                <td className="px-6 py-4 text-gray-700 capitalize">
                  {apartment.occupancy}
                </td>
                <td className="px-6 py-4 text-gray-700 capitalize">
                  {apartment.status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        // <Modal user={selectedUser} closeModal={closeModal} />
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg max-w-sm w-full overflow-y-auto max-h-[90vh] sm:max-h-[80vh]">
            <h3 className="text-2xl font-semibold mb-2">{selectedUser.name}</h3>
            <p className="text-sm text-gray-500 mb-2">{selectedUser.email}</p>
            <p className="text-sm text-gray-500 mb-2">{selectedUser.phone}</p>
            <p className="text-sm text-gray-700 mb-2">
              {selectedUser.location} {getCityName(selectedUser?.city)},
              {getCountryName(selectedUser?.country)}
            </p>
            <div>
              <h1 className="text-blue-500">Description</h1>
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: selectedUser?.description }}
              />
            </div>
            <div className="mt-4">
              <span className="inline-block text-sm text-gray-700">
                Gender: {selectedUser.gender}
              </span>
            </div>
            <div className="mt-4">
              <span className="inline-block text-sm text-gray-700">
                Occupancy: {selectedUser.occupancy}
              </span>
            </div>
            <div className="mt-4">
              <span className="inline-block text-sm text-gray-700">
                Accommodation: {selectedUser.accommodation}
              </span>
            </div>

            {/* Status Dropdown */}
            <div className="mt-4 py-4">
              <label
                htmlFor="status"
                className="block text-sm font-medium py-4 text-gray-700"
              >
                Status
              </label>
              <select
                id="status"
                className="mt-1 py-2 px-2 mb-1 block w-full rounded-md border border-gray-300 shadow-sm outline-none  sm:text-sm"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="submitted" className="px-5">
                  Submitted{" "}
                </option>
                <option value="progress" className="px-5">
                  Progress
                </option>
                <option value="finished">Finished</option>
              </select>
            </div>

            {/* Submit Button */}
            <div className="flex justify-end mt-4 gap-4">
              <button
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition-colors"
                onClick={handleStatusSubmit}
              >
                Save Status
              </button>
            </div>

            {/* Close Modal Button */}
            <div className="mt-4 flex justify-end">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 transition-colors"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HousingFormAdmin;
