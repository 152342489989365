import React, { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  useForgetPasswordConfirmMutation,
  useForgetPasswordRequestMutation,
} from "../../app/features/auth/authApi";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import EmailVerify from "../../components/PopUp/EmailVerify";

const ForgetPasswordConfirm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const token = new URLSearchParams(window.location.search).get("token");
  const navigate = useNavigate();
  const [forgetPasswordConfirm] = useForgetPasswordConfirmMutation();
  // console.log(token);

  const [showEmailVerifyNotification, setShowEmailVerifyNotification] =
    useState(false);
  const handleEmailNotifiedClose = () => {
    setShowEmailVerifyNotification(false);
    navigate("/sign-in");
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const toastId = toast.loading("Processing your request. Please wait...");

    if (data.password !== data.confirmPassword) {
      toast.error("Password and Confirm password must be same", {
        id: toastId,
        duration: 4000,
      });
      return;
    }
    if (!token) {
      toast.error("Unauthorized Access", {
        id: toastId,
        duration: 4000,
      });
      navigate("/");
      return;
    }

    try {
      const res = await forgetPasswordConfirm({
        password: data.password,
        token,
      }).unwrap();
      // console.log(res);
      if (res.message) {
        toast.success(res.message, {
          id: toastId,
          duration: 4000,
        });
        setShowEmailVerifyNotification(!showEmailVerifyNotification);
        reset();
      }
    } catch (error) {
      // console.log(error);
      if (error?.status === 400 && error.data?.errors?.password) {
        toast.error("Your password must be at least 8 characters long.", {
          id: toastId,
          duration: 4000,
        });
      } else if (error?.name === "SyntaxError") {
        toast.error(
          "We encountered a technical issue. Please clear your browser cookies and try again. If the issue persists, reach out to support for assistance.",
          { id: toastId, duration: 5000 }
        );
      } else if (error?.status === "FETCH_ERROR") {
        toast.error(
          "Network Error: Unable to connect to the server. Please check your internet connection or try again later.",
          { id: toastId, duration: 5000 }
        );
      } else {
        toast.error(
          "We encountered a server issue. Please try again after sometime!!!",
          {
            id: toastId,
            duration: 5000,
          }
        );
      }
    }
  };

  return (
    <div className="flex h-screen ">
      {showEmailVerifyNotification && (
        <EmailVerify
          onClose={handleEmailNotifiedClose}
          message={
            " Your password has been successfully reset. Now, sign in with your new password."
          }
        />
      )}
      {/* Left Pane */}
      <div className="hidden lg:flex items-center justify-center flex-1 bg-white text-black"></div>
      {/* Right Pane */}
      <div className="w-full bg-gray-100 flex items-center justify-center">
        <div className="max-w-md w-full   bg-white px-10 py-20 rounded-2xl">
          <h1 className="text-3xl font-semibold mb-6 text-black text-center">
            Reset Your Password
          </h1>

          <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
            {/* Your form elements go here */}

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  {...register("password", { required: true })}
                  name="password"
                  placeholder="Enter Your Password"
                  className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
                />
                {errors.password && (
                  <p className="text-sm mt-1 ml-2 text-red-500">
                    This field is required
                  </p>
                )}
                <div
                  className="absolute top-5 right-4 cursor-pointer "
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <>
                      <span>
                        <LuEye />
                      </span>
                    </>
                  ) : (
                    <>
                      <LuEyeOff />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-gray-700"
              >
                Confirm Password
              </label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  {...register("confirmPassword", { required: true })}
                  name="confirmPassword"
                  placeholder="Enter Your Password"
                  className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
                />
                {errors.password && (
                  <p className="text-sm mt-1 ml-2 text-red-500">
                    This field is required
                  </p>
                )}
                <div
                  className="absolute top-5 right-4 cursor-pointer "
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showPassword ? (
                    <>
                      <span>
                        <LuEye />
                      </span>
                    </>
                  ) : (
                    <>
                      <LuEyeOff />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div>
              <button
                aria-label="Sign In"
                type="submit"
                className="w-full bg-btnColor-400 text-white p-2 rounded-md hover:bg-orange-600  transition-colors duration-300 outline-none "
              >
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordConfirm;
