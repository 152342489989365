import React, { useState } from "react";
import image1 from "../../../assets/housing/house1.jpg";
import image2 from "../../../assets/housing/house2.jpg";
import image3 from "../../../assets/housing/house3.jpg";
import { IoLocationOutline, IoResize } from "react-icons/io5";
import { FaEuroSign, FaUsers } from "react-icons/fa";
import { FaLaptopHouse } from "react-icons/fa";
import { Gi3dStairs } from "react-icons/gi";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import {
  useDeleteCreatorHousingByIdMutation,
  useGetAllhousingByIdQuery,
} from "../../../app/features/housing/housingApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useGetAllCityQuery,
  useGetCountryQuery,
} from "../../../app/features/account/accountApi";
import LoadingUi from "../../../components/LoadingUi/LoadingUi";
import toast from "react-hot-toast";
import NoData from "../../../components/NoData/NoData";

const MyhousingById = () => {
  const params = useParams();
  const navigate = useNavigate();
  // console.log(params); // Should show {id: 'someId'}

  const {
    data: housingData,
    error: housingError,
    isLoading: housingLoading,
  } = useGetAllhousingByIdQuery(params?.id);
  const {
    data: cityData,
    error: cityError,
    loading: cityLoading,
  } = useGetAllCityQuery();
  const {
    data: countryData,
    error: countryError,
    loading: countryLoading,
  } = useGetCountryQuery();
  // console.log(housingData);

  const [deleteCreatorHousingById] = useDeleteCreatorHousingByIdMutation();
  const [isDeleteModalOpen, setDeleteIsModalOpen] = useState(false);
  const [selectedHousingIdForDelete, setSelectedHousingIdForDelete] =
    useState(null);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const getCityName = (cityId) => {
    const city = cityData?.results.find((city) => city.id === cityId);
    return city?.name;
  };
  const getCountryName = (cityId) => {
    const city = countryData?.results.find((city) => city.id === cityId);
    return city?.name;
  };

  const handleDeleteUser = (id) => {
    // console.log(id);

    setSelectedHousingIdForDelete(id);
    setDeleteIsModalOpen(true);
  };

  const cancleDeleteModal = () => {
    setDeleteIsModalOpen(false);
  };
  const handleConfirmDelete = async () => {
    const toastId = toast.loading("Deleting Your House. Please wait...");
    try {
      const res = await deleteCreatorHousingById(
        selectedHousingIdForDelete
      ).unwrap();
      if (res === null) {
        toast.success("Program information deleted Successfully.", {
          id: toastId,
        });
        setDeleteIsModalOpen(false);
        navigate("/student/my-housing");
      }
    } catch (error) {
      if (error?.name === "SyntaxError") {
        toast.error(
          "We encountered a technical issue. Please clear your browser cookies and try again. If the issue persists, reach out to support for assistance.",
          { id: toastId, duration: 5000 }
        );
      } else if (error?.status === "FETCH_ERROR") {
        toast.error(
          "Network Error: Unable to connect to the server. Please check your internet connection or try again later.",
          { id: toastId, duration: 5000 }
        );
      } else {
        toast.error(
          "We encountered a server issue. Please try again after sometime!!!",
          {
            id: toastId,
            duration: 5000,
          }
        );
      }
    }
  };

  if (housingLoading && cityLoading) {
    return <LoadingUi />;
  }

  return (
    <div>
      {housingData?.id ? (
        <>
          <div className="flex flex-col lg:flex-row items-center lg:items-start lg:space-x-8 p-6 bg-white z-10 h-screen container mx-auto">
            {isDeleteModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[999]">
                <div className="bg-white p-6 rounded shadow-lg w-96">
                  <h2 className="text-lg font-semibold">Confirm Delete</h2>
                  <p className="mt-4">
                    Are you sure you want to delete this item?
                  </p>
                  <div className="mt-6 flex justify-end space-x-4">
                    <button
                      onClick={cancleDeleteModal}
                      className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleConfirmDelete}
                      className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            )}
            {/* Image Section */}
            <div className="w-full md:px-4 md:w-2/3 mx-auto">
              <Swiper
                navigation={true}
                pagination={{ clickable: true }}
                modules={[Navigation, Pagination]}
                className="mySwipe  max-w-sm md:max-w-xl "
              >
                {housingData?.images.map((image, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={image.image}
                      alt={`Product Image ${index + 1}`}
                      className="w-full h-[250px] sm:h-[350px] md:h-[450px] object-cover rounded-xl"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>

              <div
                className="ql-editor mt-6 text-justify text-sm sm:text-base leading-6 text-[#002663]"
                dangerouslySetInnerHTML={{ __html: housingData?.description }}
              />
            </div>

            {/* Details Section */}
            <div className="w-full md:w-1/3   mt-6 lg:mt-0 space-y-5  ">
              <h1 className="text-3xl font-bold mb-4 text-[#002663] ">
                {housingData?.name}
              </h1>
              <p className="">
                <span className=" font-medium flex items-center  gap-1  mt-1 text-[#002663]">
                  <span className="text-3xl  text-btnColor-400   mr-2">
                    <IoLocationOutline />
                  </span>
                  {housingData?.location} , {getCityName(housingData?.city)} ,{" "}
                  {getCountryName(housingData?.country)}
                </span>
              </p>

              <p className="">
                <span className=" font-medium flex items-center  gap-1  mt-1 text-[#002663]">
                  <span className="text-3xl  text-btnColor-400  mr-2">
                    <FaLaptopHouse />
                  </span>
                  <span className="capitalize"> {housingData?.types}</span>{" "}
                  Appartment ,{housingData?.details?.rooms}H
                  {housingData?.details?.bathrooms}B
                  {housingData?.details?.kitchen}k
                </span>
              </p>
              <p className="">
                <span className=" font-medium flex items-center  gap-1  mt-1 text-[#002663]">
                  <span className="text-3xl  text-btnColor-400  mr-2">
                    <FaEuroSign />
                  </span>
                  {housingData?.details?.rent_per_month} &euro;/month
                </span>
              </p>
              <p className="">
                <span className=" font-medium flex items-center  gap-1  mt-1 text-[#002663]">
                  <span className="text-3xl  text-btnColor-400  mr-2">
                    <Gi3dStairs />
                  </span>
                  {housingData?.details?.floor}th Floor
                </span>
              </p>

              <p className="">
                <span className=" font-medium flex items-center  gap-1  mt-1 text-[#002663]">
                  <span className="text-3xl  text-btnColor-400  mr-2">
                    <IoResize />
                  </span>
                  {housingData?.apartment_size}
                </span>
              </p>
              <p className="">
                <span className=" font-medium flex items-center  gap-1  mt-1 text-[#002663]">
                  <span className="text-3xl  text-btnColor-400  mr-2">
                    <FaUsers />
                  </span>
                  <span className="text-xl font-semibold text-red-400">
                    {housingData?.details?.allowed_person}
                  </span>{" "}
                  Allow Persons
                </span>
              </p>

              <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2 text-[#002663]">
                  Features
                </h2>
                <ul className="list-disc list-inside ">
                  {housingData?.features.map((amenity, index) => (
                    <li key={index} className="  text-btnColor-400  mr-2">
                      {" "}
                      <span className=" text-[#002663] font-medium ">
                        {amenity.name}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="mb-1">
                <h2 className="text-2xl font-semibold mb-2 text-[#002663]">
                  Nearest University Info
                </h2>

                <div>
                  <h1 className="text-lg font-medium text-[#002663]">
                    {" "}
                    Name : <span>{housingData?.nearest[0].name}</span>
                  </h1>
                  <h1 className="text-base font-medium text-[#002663]">
                    {" "}
                    Distance :{" "}
                    <span className="text-red-600">
                      {housingData?.nearest[0].distance}
                    </span>
                  </h1>
                </div>
              </div>

              <div className="p-6 bg-white shadow-md rounded-lg max-w-sm lg:mx-auto">
                <h2 className="text-2xl font-semibold text-btnColor-400 mb-4 text-center">
                  Booking Details
                </h2>

                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <span className="text-green-600 font-medium">
                      Abailable In :
                    </span>
                    <span className="text-gray-900 font-bold">
                      {formatDate(housingData?.details?.rent_start_date)}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-red-600 font-medium">
                      Ending Date:
                    </span>
                    {housingData?.details?.rent_end_date ? (
                      <span className="text-gray-900 font-bold">
                        {formatDate(housingData?.details?.rent_end_date)}
                      </span>
                    ) : (
                      <span className="text-gray-900 font-bold">
                        No Ending Date
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="p-6 bg-white shadow-md rounded-lg max-w-sm lg:mx-auto">
                <h2 className="text-2xl font-semibold text-btnColor-400 mb-4 text-center">
                  Contact Information
                </h2>

                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <span className="text-green-600 font-medium">Email :</span>
                    <span className="text-gray-900 font-bold">
                      {housingData?.email}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-green-600 font-medium">
                      Phone Number :
                    </span>
                    <span className="text-gray-900 font-bold">
                      {housingData?.phone}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-green-600 font-medium">
                      WhatsApp Number :
                    </span>
                    <span className="text-gray-900 font-bold">
                      {housingData?.whatsapp}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex justify-center items-center pt-8">
                <Link to={`/student/my-housing-update/${housingData?.id}`}>
                  {" "}
                  <button className="bg-gradient-to-r bg-blue-700 block w-[150px] py-2 mx-4 mb-5 rounded text-white font-semibold">
                    Update
                  </button>
                </Link>
                <button
                  className="bg-gradient-to-r bg-red-600 block w-[150px] py-2 mx-4 mb-5 rounded-lg text-white font-semibold"
                  onClick={() => handleDeleteUser(housingData?.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <NoData />
        </>
      )}
    </div>
  );
};

export default MyhousingById;
