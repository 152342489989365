import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import Select from "react-select";
import "react-quill/dist/quill.snow.css";
import {
  useGetAllCityQuery,
  useGetCountryQuery,
  useGetProfileQuery,
} from "../../app/features/account/accountApi";
import { useContactUsForHousingMutation } from "../../app/features/housing/housingApi";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import SEOData from "../../components/SEO/SEO";
import LoadingUi from "../../components/LoadingUi/LoadingUi";

const FindHouse = () => {
  const editorStyle = {
    border: "1px solid #ccc", // Custom border
    borderRadius: "8px", // Custom border radius
    padding: "15px", // Custom padding inside the editor
    height: "250px", // Custom height
    fontFamily: "Arial, sans-serif", // Custom font
    fontSize: "16px", // Custom font size
    overflowY: "auto", // Scroll if content overflows
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6] }],
      [{ size: [] }],
      [{ color: [] }, { background: [] }], // Add color and background color options
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"], // remove formatting button
    ],
  };

  const {
    data: countries,
    isLoading: countryLoading,
    refetch: countryRefetch,
  } = useGetCountryQuery(undefined);
  const {
    data: allCitys,
    isLoading: allCitysLoading,
    refetch: allCitysReferth,
  } = useGetAllCityQuery();

  const countryLocation = countries?.results
    ?.filter((country) => country.id === 73)
    ?.map((country) => ({
      value: country.id,
      label: country.name,
    }));

  const cityLocation = allCitys?.results?.map((country) => ({
    value: country.id,
    label: country.name,
  }));
  const typeOfHousing = [
    { value: "full", label: "Full Appartment" },
    { value: "shared", label: "Shared Appartment" },
  ];

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "both", label: "Both" },
  ];

  const occupancyOptions = [
    { value: "single", label: "Single" },
    { value: "double", label: "Married" },
  ];

  const [contactUsForHousing] = useContactUsForHousingMutation();
  const [description, setDescription] = useState("");
  const navigate = useNavigate();

  const {
    data: myProfile,
    isLoading: myProfileLoading,
    refetch: profileRefetch,
  } = useGetProfileQuery();
  // console.log(myProfile);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // console.log(data);
    const contactData = {
      name: `${myProfile?.first_name} ${myProfile?.last_name}`,
      email: myProfile?.email,
      phone: myProfile?.phone,
      location: data?.location,
      gender: data?.gender,
      occupancy: data?.Occupancy,
      accommodation: data?.appartmentType,
      country: data?.country,
      city: data?.city,
      description: description || "",
    };
    // console.log(contactData);
    const toastId = toast.loading("Creating Your Request. Please wait...");
    try {
      const res = await contactUsForHousing(contactData).unwrap();
      if (res.id) {
        toast.success("Request Successfully Created", { id: toastId });
        navigate("/student/my-house-request");
        reset();
        setDescription("");
      }
      // console.log(res);
    } catch (error) {
      // console.log(error);
      if (error?.name === "SyntaxError") {
        toast.error(
          "We encountered a technical issue. Please clear your browser cookies and try again. If the issue persists, reach out to support for assistance.",
          { id: toastId, duration: 5000 }
        );
      } else if (error?.status === "FETCH_ERROR") {
        toast.error(
          "Network Error: Unable to connect to the server. Please check your internet connection or try again later.",
          { id: toastId, duration: 5000 }
        );
      } else {
        toast.error(
          "We encountered a server issue. Please try again after sometime!!!",
          {
            id: toastId,
            duration: 5000,
          }
        );
      }
    }
  };

  useEffect(() => {
    profileRefetch();
  }, [profileRefetch]);

  if (myProfileLoading) {
    return <LoadingUi />;
  }

  return (
    <div className="min-h-screen bg-slate-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <SEOData
        title={`UniEdu || Find Housing`}
        description={`UniEdu Find Housing page.`}
        canonical={window.location.pathname}
      />
      <div className="max-w-4xl w-full bg-slate-100  mx-auto  p-5 lg:p-10 rounded-xl mt-5 shadow-lg">
        <div className="flex flex-col justify-center items-center p-6 bg-gradient-to-r from-blue-50 to-blue-100 rounded-lg shadow-lg  mx-auto">
          <h3 className="text-2xl text-blue-600 font-semibold mb-4">
            If you didn&apos;t find any house that matches your needs,
          </h3>
          <h3 className="text-base text-gray-700 font-medium text-center">
            Please submit this form, and we will help you find the perfect house
            based on your preferences.
          </h3>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-1 md:px-10 gap-5 pt-10">
            {/* Company Name */}
            <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-5 mb-4">
              <div className="flex items-center">
                <div className="w-1/3">
                  <label className="font-medium text-sm">Your Name</label>
                </div>
                <div className="w-2/3">
                  <input
                    type="text"
                    value={`${myProfile?.first_name} ${myProfile?.last_name}`}
                    placeholder="Your Name"
                    className="w-full h-full py-3 px-2 border-none rounded-lg focus:outline-blue-200 placeholder:text-sm"
                    {...register("yourName", { required: true })}
                  />
                  {errors.yourName && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
              </div>
            </div>

            {/* Representative Name */}
            <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-5 mb-4">
              {/* Email */}
              <div className="flex items-center">
                <div className="w-1/3">
                  <label className="font-medium text-sm">Email</label>
                </div>
                <div className="w-2/3">
                  <input
                    type="email"
                    value={myProfile?.email}
                    placeholder="Enter your email"
                    className="w-full h-full py-3 px-2 border-none rounded-lg focus:outline-blue-200 placeholder:text-sm"
                    {...register("email", { required: true })}
                  />
                  {errors.email && (
                    <span className="text-red-500">
                      A valid email is required
                    </span>
                  )}
                </div>
              </div>

              {/* Phone */}
              <div className="flex items-center">
                <div className="w-1/3">
                  <label className="font-medium text-sm">Phone</label>
                </div>
                <div className="w-2/3">
                  <input
                    type="text"
                    value={myProfile?.phone}
                    placeholder="Your phone number"
                    className="w-full h-full py-3 px-2 border-none rounded-lg focus:outline-blue-200 placeholder:text-sm"
                    {...register("phone", { required: true })}
                  />
                  {errors.phone && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
              </div>

              {/* Country */}
              <div className="flex items-center">
                <div className="w-1/3">
                  <label className="font-medium text-sm">Country</label>
                </div>
                <div className="w-2/3">
                  <div>
                    <Controller
                      name="country"
                      control={control}
                      rules={{
                        required: "This field is required",
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={countryLocation}
                          value={countryLocation?.find(
                            (option) => option.value === field.value
                          )}
                          // onChange={(selectedOption) =>
                          //   field.onChange(
                          //     selectedOption ? selectedOption.value : null
                          //   )
                          // }
                          onChange={(selectedOption) => {
                            field.onChange(
                              selectedOption ? selectedOption.value : null
                            );
                          }}
                          isClearable
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              outline: "none",
                              border: "none",
                              padding: "5px 0",
                            }),
                            placeholder: (provider) => ({
                              ...provider,
                              fontSize: "14px",
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              color: "blue",
                              fontWeight: "bold",
                            }),
                            multiValue: (provided) => ({
                              ...provided,
                              fontWeight: "500",
                              padding: "2px 0",
                              background: "white",
                              border: "1px solid #ddd",
                              borderRadius: "5px",
                              marginLeft: "10px",
                            }),
                            multiValueLabel: (provided) => ({
                              ...provided,
                              opacity: "0.8",
                            }),
                          }}
                          placeholder="Select your country"
                          isSearchable
                          // isMulti
                          noOptionsMessage={() => "No Match found"}
                        />
                      )}
                    />
                    {errors.country && (
                      <p className="text-red-500 font-medium ml-3 mt-1">
                        {errors.country.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {/* Country */}
              <div className="flex items-center">
                <div className="w-1/3">
                  <label className="font-medium text-sm">City</label>
                </div>
                <div className="w-2/3">
                  <div>
                    <Controller
                      name="city"
                      control={control}
                      rules={{
                        required: "This field is required",
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={cityLocation}
                          value={cityLocation?.find(
                            (option) => option.value === field.value
                          )}
                          // onChange={(selectedOption) =>
                          //   field.onChange(
                          //     selectedOption ? selectedOption.value : null
                          //   )
                          // }
                          onChange={(selectedOption) => {
                            field.onChange(
                              selectedOption ? selectedOption.value : null
                            );
                          }}
                          isClearable
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              outline: "none",
                              border: "none",
                              padding: "5px 0",
                            }),
                            placeholder: (provider) => ({
                              ...provider,
                              fontSize: "14px",
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              color: "blue",
                              fontWeight: "bold",
                            }),
                            multiValue: (provided) => ({
                              ...provided,
                              fontWeight: "500",
                              padding: "2px 0",
                              background: "white",
                              border: "1px solid #ddd",
                              borderRadius: "5px",
                              marginLeft: "10px",
                            }),
                            multiValueLabel: (provided) => ({
                              ...provided,
                              opacity: "0.8",
                            }),
                          }}
                          placeholder="Select your city"
                          isSearchable
                          // isMulti
                          noOptionsMessage={() => "No Match found"}
                        />
                      )}
                    />
                    {errors.city && (
                      <p className="text-red-500 font-medium ml-3 mt-1">
                        {errors.city.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* Address */}
              <div className="flex items-center">
                <div className="w-1/3">
                  <label className="font-medium text-sm">
                    Which area you looking for
                  </label>
                </div>
                <div className="w-2/3">
                  <input
                    type="text"
                    placeholder="Tell us the area you’re interested in!"
                    className="w-full h-full py-3 px-2 border-none rounded-lg focus:outline-blue-200 placeholder:text-sm"
                    {...register("location", { required: true })}
                  />
                  {errors.location && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
              </div>

              <div className="flex items-center">
                <div className="w-1/3">
                  <label className="font-medium text-sm">Appartment Type</label>
                </div>
                <div className="w-2/3">
                  <div>
                    <Controller
                      name="appartmentType"
                      control={control}
                      rules={{
                        required: "This field is required",
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={typeOfHousing}
                          value={typeOfHousing?.find(
                            (option) => option.value === field.value
                          )}
                          // onChange={(selectedOption) =>
                          //   field.onChange(
                          //     selectedOption ? selectedOption.value : null
                          //   )
                          // }
                          onChange={(selectedOption) => {
                            field.onChange(
                              selectedOption ? selectedOption.value : null
                            );
                          }}
                          isClearable
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              outline: "none",
                              border: "none",
                              padding: "5px 0",
                            }),
                            placeholder: (provider) => ({
                              ...provider,
                              fontSize: "14px",
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              color: "blue",
                              fontWeight: "bold",
                            }),
                            multiValue: (provided) => ({
                              ...provided,
                              fontWeight: "500",
                              padding: "2px 0",
                              background: "white",
                              border: "1px solid #ddd",
                              borderRadius: "5px",
                              marginLeft: "10px",
                            }),
                            multiValueLabel: (provided) => ({
                              ...provided,
                              opacity: "0.8",
                            }),
                          }}
                          placeholder="Your Appartment Type"
                          isSearchable
                          // isMulti
                          noOptionsMessage={() => "No Match found"}
                        />
                      )}
                    />
                    {errors.appartmentType && (
                      <p className="text-red-500 font-medium ml-3 mt-1">
                        {errors.appartmentType.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="flex items-center">
              <div className="w-1/3">
                <label className="font-medium text-sm">Gender</label>
              </div>
              <div className="w-2/3">
                <div>
                  <Controller
                    name="country"
                    control={control}
                    rules={{
                      required: "This field is required",
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={genderOptions}
                        value={genderOptions?.find(
                          (option) => option.value === field.value
                        )}
                        // onChange={(selectedOption) =>
                        //   field.onChange(
                        //     selectedOption ? selectedOption.value : null
                        //   )
                        // }
                        onChange={(selectedOption) => {
                          field.onChange(
                            selectedOption ? selectedOption.value : null
                          );
                        }}
                        isClearable
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            outline: "none",
                            border: "none",
                            padding: "5px 0",
                          }),
                          placeholder: (provider) => ({
                            ...provider,
                            fontSize: "14px",
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            color: "blue",
                            fontWeight: "bold",
                          }),
                          multiValue: (provided) => ({
                            ...provided,
                            fontWeight: "500",
                            padding: "2px 0",
                            background: "white",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                            marginLeft: "10px",
                          }),
                          multiValueLabel: (provided) => ({
                            ...provided,
                            opacity: "0.8",
                          }),
                        }}
                        placeholder="Select your country"
                        isSearchable
                        // isMulti
                        noOptionsMessage={() => "No Match found"}
                      />
                    )}
                  />
                  {errors.country && (
                    <p className="text-red-500 font-medium ml-3 mt-1">
                      {errors.country.message}
                    </p>
                  )}
                </div>
              </div>
            </div>  
            <div className="flex items-center">
              <div className="w-1/3">
                <label className="font-medium text-sm">Age Group </label>
              </div>
              <div className="w-2/3">
                <div>
                  <Controller
                    name="country"
                    control={control}
                    rules={{
                      required: "This field is required",
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={ageGroupOptions}
                        value={ageGroupOptions?.find(
                          (option) => option.value === field.value
                        )}
                        // onChange={(selectedOption) =>
                        //   field.onChange(
                        //     selectedOption ? selectedOption.value : null
                        //   )
                        // }
                        onChange={(selectedOption) => {
                          field.onChange(
                            selectedOption ? selectedOption.value : null
                          );
                        }}
                        isClearable
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            outline: "none",
                            border: "none",
                            padding: "5px 0",
                          }),
                          placeholder: (provider) => ({
                            ...provider,
                            fontSize: "14px",
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            color: "blue",
                            fontWeight: "bold",
                          }),
                          multiValue: (provided) => ({
                            ...provided,
                            fontWeight: "500",
                            padding: "2px 0",
                            background: "white",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                            marginLeft: "10px",
                          }),
                          multiValueLabel: (provided) => ({
                            ...provided,
                            opacity: "0.8",
                          }),
                        }}
                        placeholder="Select your country"
                        isSearchable
                        // isMulti
                        noOptionsMessage={() => "No Match found"}
                      />
                    )}
                  />
                  {errors.country && (
                    <p className="text-red-500 font-medium ml-3 mt-1">
                      {errors.country.message}
                    </p>
                  )}
                </div>
              </div>
            </div>  
            <div className="flex items-center">
              <div className="w-1/3">
                <label className="font-medium text-sm">Occupancy Options</label>
              </div>
              <div className="w-2/3">
                <div>
                  <Controller
                    name="country"
                    control={control}
                    rules={{
                      required: "This field is required",
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={occupancyOptions}
                        value={occupancyOptions?.find(
                          (option) => option.value === field.value
                        )}
                        // onChange={(selectedOption) =>
                        //   field.onChange(
                        //     selectedOption ? selectedOption.value : null
                        //   )
                        // }
                        onChange={(selectedOption) => {
                          field.onChange(
                            selectedOption ? selectedOption.value : null
                          );
                        }}
                        isClearable
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            outline: "none",
                            border: "none",
                            padding: "5px 0",
                          }),
                          placeholder: (provider) => ({
                            ...provider,
                            fontSize: "14px",
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            color: "blue",
                            fontWeight: "bold",
                          }),
                          multiValue: (provided) => ({
                            ...provided,
                            fontWeight: "500",
                            padding: "2px 0",
                            background: "white",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                            marginLeft: "10px",
                          }),
                          multiValueLabel: (provided) => ({
                            ...provided,
                            opacity: "0.8",
                          }),
                        }}
                        placeholder="Select your country"
                        isSearchable
                        // isMulti
                        noOptionsMessage={() => "No Match found"}
                      />
                    )}
                  />
                  {errors.country && (
                    <p className="text-red-500 font-medium ml-3 mt-1">
                      {errors.country.message}
                    </p>
                  )}
                </div>
              </div>
            </div>   */}

              <div>
                <h4 className="text-blue-500 font-semibold mb-2">Gender</h4>
                {genderOptions.map((option) => (
                  <div className="flex" key={option.value}>
                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        {...register("gender", {
                          required: "Please select your gender",
                        })}
                        value={option.value}
                      />
                      {option.label}
                    </label>
                  </div>
                ))}
                {errors.gender && (
                  <p className="text-red-500 text-sm">
                    {errors.gender.message}
                  </p>
                )}
              </div>

              {/* Occupancy Options */}
              <div className="mt-2">
                <h4 className="text-blue-500 font-semibold mb-2">
                  Marital Status
                </h4>
                {occupancyOptions.map((option) => (
                  <div className="flex" key={option.value}>
                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        {...register("occupancy", {
                          required: "Please select an occupancy option",
                        })}
                        value={option.value}
                      />
                      {option.label}
                    </label>
                  </div>
                ))}
                {errors.occupancy && (
                  <p className="text-red-500 text-sm">
                    {errors.occupancy.message}
                  </p>
                )}
              </div>
            </div>

            <div className="w-full mt-8 bg-white">
              <ReactQuill
                value={description}
                onChange={setDescription}
                style={editorStyle}
                modules={modules}
              />
            </div>

            <div className="flex justify-center mt-5">
              <button
                type="submit"
                className=" w-[300px] bg-btnColor-400 text-white font-semibold py-2 px-5 rounded-lg hover:bg-blue-600 transition"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FindHouse;
