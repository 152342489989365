import { baseApi } from "../../api/baseApi";

const accountApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    applicant: builder.query({
      query: () => ({
        url: `/education/applicant/profile/`,
        method: "GET",
      }),
      providesTags: ["applicant"],
    }),
    updateApplicant: builder.mutation({
      query: (applicantData) => ({
        url: "/education/applicant/profile/",
        method: "POST",
        body: applicantData,
      }),
      invalidatesTags: ["applicant"],
    }),
    getProfile: builder.query({
      query: () => ({
        url: "/account/profile/",
        method: "GET",
      }),
    }),
    deleteProfile: builder.mutation({
      query: (id) => ({
        url: `/account/delete/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["experience"],
    }),

    updateProfile: builder.mutation({
      query: (profileData) => ({
        url: "/account/profile/",
        method: "PUT",
        body: profileData,
      }),
      invalidatesTags: ["applicant"],
    }),
    getCountry: builder.query({
      query: () => ({
        url: "/account/country/?records=500",
        method: "GET",
      }),
      providesTags: ["country"],
    }),
    getCity: builder.query({
      query: (country) => ({
        url: `/account/country/${country}/`,
        method: "GET",
      }),
      providesTags: ["city"],
    }),
    getAllCity: builder.query({
      query: () => ({
        url: "/account/city/",
        method: "GET",
      }),
      providesTags: ["city"],
    }),
  }),
});

export const {
  useApplicantQuery,
  useUpdateApplicantMutation,
  useGetProfileQuery,
  useUpdateProfileMutation,
  useGetCountryQuery,
  useGetCityQuery,
  useDeleteProfileMutation,
  useGetAllCityQuery,
} = accountApi;
