import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";

import { FaTimes } from "react-icons/fa";
import {
  useGetAllCityQuery,
  useGetCountryQuery,
} from "../../../app/features/account/accountApi";
import toast from "react-hot-toast";
import {
  useCreateHousingImageMutation,
  useCreateHousingMutation,
  useDeleteHousingImageMutation,
  useGetAllhousingByIdQuery,
  useUpdateHousingByIdMutation,
} from "../../../app/features/housing/housingApi";
import ReactQuill from "react-quill";
import { useNavigate, useParams } from "react-router-dom";
import LoadingUi from "../../../components/LoadingUi/LoadingUi";
import SEOData from "../../../components/SEO/SEO";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6] }],
    [{ size: [] }],
    [{ color: [] }, { background: [] }], // Add color and background color options
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    ["clean"], // remove formatting button
  ],
};
const UpdateHousingById = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const editorStyle = {
    border: "1px solid #ccc", // Custom border
    borderRadius: "8px", // Custom border radius
    padding: "15px", // Custom padding inside the editor
    height: "250px", // Custom height
    fontFamily: "Arial, sans-serif", // Custom font
    fontSize: "16px", // Custom font size
    overflowY: "auto", // Scroll if content overflows
  };
  const [roomAvailable, setRoomAvailable] = useState(null);
  const [isUntilChecked, setIsUntilChecked] = useState(false);
  const {
    data: countries,
    error: countryError,
    loading: countryLoading,
    refetch: countryReferth,
  } = useGetCountryQuery();
  const {
    data: allCitys,
    error: allCitysError,
    loading: allCitysLoading,
    refetch: allCitysReferth,
  } = useGetAllCityQuery();

  // console.log(allCitys);

  const [createHousingImage] = useCreateHousingImageMutation();
  const [deleteHousingImage] = useDeleteHousingImageMutation();
  const [updateHousingById] = useUpdateHousingByIdMutation();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const [imagePreviews, setImagePreviews] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);

  // console.log(uploadedImages);

  const onSubmit = async (data) => {
    // console.log("Form data received:", data); // Debugging step

    const appartmentsDetails = {
      details: {
        ...(data?.floor && { floor: data.floor }),
        ...(data?.rooms && { rooms: data.rooms }),
        ...(data?.kitchen && { kitchen: data.kitchen }),
        ...(data?.bathroom && { bathrooms: data.bathroom }),
        ...(data?.allowed_person && {
          allowed_person: parseInt(data.allowed_person),
        }),
        ...(data?.parkingOption && {
          has_parking: data.parkingOption.value || data.parkingOption,
        }),
        rent_per_month: data?.price_per_month || 0,
        ...(data?.rooms_available && { rent_start_date: data.rooms_available }),
        ...(isUntilChecked
          ? {}
          : data?.rooms_end && { rent_end_date: data.rooms_end }),
      },
      features: data?.features?.map((feature) => ({ name: feature })) || [],
      nearest: [
        {
          name: data?.nearestUniversiyy,
          distance: data?.distanceFromUniversity,
        },
      ],
      ...(data?.name && { name: data.name }),
      ...(data?.houseAddress && { location: data.houseAddress }),
      ...(data?.size_of_the_apartment && {
        apartment_size: data.size_of_the_apartment,
      }),
      ...(data?.gender && { gender: data.gender.value || data.gender }),
      ...(data?.appartmentType && {
        types: data.appartmentType?.value || data.appartmentType,
      }),
      ...(description && { description }),
      country: data?.countryLocation || data?.countryLocation?.value,
      city: data?.cityLocation?.value || data?.cityLocation,
      email: data?.emailAddress,
      phone: data?.phoneNumber,
      whatsapp: data?.whatsAppNumber,
      status: "pending",
    };

    //  console.log("Constructed appartmentsDetails:", appartmentsDetails); // Debugging

    const toastId = toast.loading("Updating Housing. Please wait...");

    try {
      const res = await updateHousingById({
        updateData: appartmentsDetails,
        id: params?.id,
      }).unwrap();
      // console.log("Update response:", res); // Debugging

      if (res?.id && data?.images) {
        const formData = new FormData();
        for (const file of Array.from(data.images)) {
          formData.append("image", file);
          try {
            await createHousingImage({ id: res.id, formData });
            // console.log("Image upload successful");
          } catch (error) {
            // console.error("Error uploading image:", error);
          }
          formData.delete("image"); // Clear previous file for next
        }
      }

      housingDataRefetch();

      toast.success("Successfully Update Housing.", {
        id: toastId,
        duration: 4000,
      });
      reset();
      navigate("/student/my-housing");
      setDescription("");
      setImagePreviews([]);
    } catch (error) {
      // console.log(error);
      if (error?.name === "SyntaxError") {
        toast.error(
          "We encountered a technical issue. Please clear your browser cookies and try again. If the issue persists, reach out to support for assistance.",
          { id: toastId, duration: 5000 }
        );
      } else if (error?.status === "FETCH_ERROR") {
        toast.error(
          "Network Error: Unable to connect to the server. Please check your internet connection or try again later.",
          { id: toastId, duration: 5000 }
        );
      } else {
        toast.error(
          "We encountered a server issue. Please try again after sometime!!!",
          {
            id: toastId,
            duration: 5000,
          }
        );
      }
    }
  };

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const previews = files.map((file) => URL.createObjectURL(file));

    setImagePreviews([...imagePreviews, ...previews]);
    setUploadedImages([...uploadedImages, ...files]);
  };

  const handleRemoveImage = (index) => {
    const updatedPreviews = imagePreviews.filter((_, i) => i !== index);
    const updatedImages = uploadedImages.filter((_, i) => i !== index);

    setImagePreviews(updatedPreviews);
    setUploadedImages(updatedImages);
  };

  const handleDeleteImage = async (aprtId, imgId) => {
    // console.log(aprtId, imgId);
    // const toastId= toast.loading("Deleteing Your exising image")
    try {
      const res = await deleteHousingImage({ aprtId, imgId }).unwrap();
      // console.log(res);
      housingDataRefetch();
    } catch (error) {
      if (error?.name === "SyntaxError") {
        toast.error(
          "We encountered a technical issue. Please clear your browser cookies and try again. If the issue persists, reach out to support for assistance."
          // { id: toastId, duration: 5000 }
        );
      } else if (error?.status === "FETCH_ERROR") {
        toast.error(
          "Network Error: Unable to connect to the server. Please check your internet connection or try again later."
          // { id: toastId, duration: 5000 }
        );
      } else {
        toast.error(
          "We encountered a server issue. Please try again after sometime!!!"
          // {
          //   id: toastId,
          //   duration: 5000,
          // }
        );
      }
    }
  };

  const countryLocation = countries?.results
    ?.filter((country) => country.id === 73)
    ?.map((country) => ({
      value: country.id,
      label: country.name,
    }));
  const cityLocation = allCitys?.results?.map((country) => ({
    value: country.id,
    label: country.name,
  }));
  // const cityLocation = [
  //   { value: "helsinky", label: "helsinky" },
  //   { value: "Bangladesh", label: "Bangladesh" },
  // ];
  const appartmetType = [
    { value: "full", label: "Full Appartment" },
    { value: "shared", label: "Shared Appartment" },
  ];
  const parkingOptions = [
    { value: true, label: "Available" },
    { value: false, label: "Not Available" },
  ];
  const gender = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female " },
  ];
  const amenitiesOptions = [
    { value: "Air Conditioning", label: "Air Conditioning" },
    { value: "Wi-Fi", label: "Wi-Fi" },
    { value: "Gym Access", label: "Gym Access" },
    { value: "Parking", label: "Parking" },
  ];

  const {
    data: housingData,
    error: housingError,
    isLoading: housingLoading,
    refetch: housingDataRefetch,
  } = useGetAllhousingByIdQuery(params?.id);

  // console.log(housingData);

  useEffect(() => {
    if (housingData?.types) {
      setValue("appartmentType", housingData?.types);
    }
    if (housingData?.name) {
      setValue("name", housingData?.name);
    }
    if (housingData?.country) {
      setValue("countryLocation", housingData?.country);
    }
    if (housingData?.city) {
      setValue("cityLocation", housingData?.city);
    }
    if (housingData?.location) {
      setValue("houseAddress", housingData?.location);
    }
    if (housingData?.nearest[0]?.name) {
      setValue("nearestUniversiyy", housingData?.nearest[0]?.name);
    }
    if (housingData?.nearest[0]?.distance) {
      setValue("distanceFromUniversity", housingData?.nearest[0]?.distance);
    }
    if (housingData?.details?.rent_per_month) {
      setValue("price_per_month", housingData?.details?.rent_per_month);
    }
    if (housingData?.details?.allowed_person) {
      setValue("allowed_person", housingData?.details?.allowed_person);
    }
    if (housingData?.details?.rooms) {
      setValue("rooms", housingData?.details?.rooms);
    }
    if (housingData?.details?.kitchen) {
      setValue("kitchen", housingData?.details?.kitchen);
    }
    if (housingData?.details?.bathrooms) {
      setValue("bathroom", housingData?.details?.bathrooms);
    }
    if (housingData?.details?.floor) {
      setValue("floor", housingData?.details?.floor);
    }
    if (housingData?.apartment_size) {
      setValue("size_of_the_apartment", housingData?.apartment_size);
    }
    if (housingData?.gender) {
      setValue("gender", housingData?.gender);
    }
    if (housingData?.features) {
      const featureNames = housingData.features.map((feature) => feature.name);
      // console.log(featureNames);
      setValue("features", featureNames);
    }
    if (housingData?.details?.has_parking) {
      setValue("parkingOption", housingData?.details?.has_parking);
    }
    if (housingData?.details?.rent_end_date) {
      setValue("rooms_end", housingData?.details?.rent_end_date);
    }
    if (housingData?.details?.rent_start_date) {
      setValue("rooms_available", housingData?.details?.rent_start_date);
    }
    if (housingData?.whatsapp) {
      setValue("whatsAppNumber", housingData?.whatsapp);
    }
    if (housingData?.phone) {
      setValue("phoneNumber", housingData?.phone);
    }
    if (housingData?.email) {
      setValue("emailAddress", housingData?.email);
    }

    if (housingData?.description) {
      setDescription(housingData?.description || "");
    }
  }, [housingData, setValue]);

  if (housingLoading) {
    return <LoadingUi />;
  }

  return (
    <div className="min-h-screen bg-slate-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <SEOData
        title={`UniEdu || Update Housing`}
        description={`UniEdu Update Housing page.`}
        canonical={window.location.pathname}
      />
      <div className="max-w-4xl w-full bg-white shadow-lg rounded-lg p-8">
        <h2 className="text-3xl font-semibold text-center text-blue-500 mb-6 ">
          Add New Apartment
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            {/* Apartment Type */}
            <div>
              <label className="block font-medium text-black mb-1">
                Apartment Type
              </label>
              <div>
                <Controller
                  name="appartmentType"
                  control={control}
                  rules={{ required: "This field is required" }}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        options={appartmetType}
                        value={appartmetType?.find(
                          (option) => option.value === field.value
                        )}
                        onChange={(selectedOption) => {
                          field.onChange(
                            selectedOption ? selectedOption.value : null
                          );
                        }}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            outline: "none",
                            padding: "3px 0",
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            color: "blue",
                            fontWeight: "bold",
                          }),
                          // placeholder: (provider) => ({
                          //   ...provider,
                          //   fontSize: "14px",
                          // }),
                          multiValue: (provided) => ({
                            ...provided,
                            fontWeight: "500",
                            padding: "2px 0",
                            background: "white",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                            marginLeft: "10px",
                          }),
                          multiValueLabel: (provided) => ({
                            ...provided,
                            opacity: "0.8",
                          }),
                        }}
                        placeholder="Select your housing type "
                        isSearchable
                        isClearable
                        noOptionsMessage={() => "No Match found"}
                      />
                      {errors.appartmentType && (
                        <p className="text-red-500 font-medium ml-3 mt-1">
                          {errors.appartmentType.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
            </div>

            {/* Apartment Name */}
            <div>
              <label className="block   font-medium text-black mb-1">
                Apartment Name
              </label>
              <input
                type="text"
                {...register("name", {
                  required: "Apartment Name is required",
                })}
                placeholder="Apartment Name"
                className="w-full border border-gray-300 rounded-lg py-2 px-4 text-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              />
              {errors.name && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.name.message}
                </p>
              )}
            </div>

            {/* Location */}
            <div>
              <label className="block   font-medium text-black mb-1">
                Country
              </label>
              <div>
                <Controller
                  name="countryLocation"
                  control={control}
                  rules={{ required: "This field is required" }}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        options={countryLocation}
                        value={countryLocation?.find(
                          (option) => option.value === field.value
                        )}
                        onChange={(selectedOption) => {
                          field.onChange(
                            selectedOption ? selectedOption.value : null
                          );
                        }}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            outline: "none",
                            padding: "3px 0",
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            color: "blue",
                            fontWeight: "bold",
                          }),
                          // placeholder: (provider) => ({
                          //   ...provider,
                          //   fontSize: "14px",
                          // }),
                          multiValue: (provided) => ({
                            ...provided,
                            fontWeight: "500",
                            padding: "2px 0",
                            background: "white",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                            marginLeft: "10px",
                          }),
                          multiValueLabel: (provided) => ({
                            ...provided,
                            opacity: "0.8",
                          }),
                        }}
                        placeholder="Country"
                        isSearchable
                        isClearable
                        noOptionsMessage={() => "No Match found"}
                      />
                      {errors.countryLocation && (
                        <p className="text-red-500 font-medium ml-3 mt-1">
                          {errors.countryLocation.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
            </div>
            <div>
              <label className="block   font-medium text-black mb-1">
                City
              </label>
              <div>
                <Controller
                  name="cityLocation"
                  control={control}
                  rules={{ required: "This field is required" }}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        options={cityLocation}
                        value={cityLocation?.find(
                          (option) => option.value === field.value
                        )}
                        onChange={(selectedOption) => {
                          field.onChange(
                            selectedOption ? selectedOption.value : null
                          );
                        }}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            outline: "none",
                            padding: "3px 0",
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            color: "blue",
                            fontWeight: "bold",
                          }),
                          // placeholder: (provider) => ({
                          //   ...provider,
                          //   fontSize: "14px",
                          // }),
                          multiValue: (provided) => ({
                            ...provided,
                            fontWeight: "500",
                            padding: "2px 0",
                            background: "white",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                            marginLeft: "10px",
                          }),
                          multiValueLabel: (provided) => ({
                            ...provided,
                            opacity: "0.8",
                          }),
                        }}
                        placeholder="Select city "
                        isSearchable
                        isClearable
                        noOptionsMessage={() => "No Match found"}
                      />
                      {errors.cityLocation && (
                        <p className="text-red-500 font-medium ml-3 mt-1">
                          {errors.cityLocation.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
            </div>
            <div>
              <label className="block   font-medium text-black-700 mb-1">
                House Address
              </label>
              <input
                type="text"
                {...register("houseAddress", {
                  required: "Location is required",
                })}
                placeholder="House Address"
                className="w-full border border-gray-300 rounded-lg py-2 px-4 text-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500 "
              />
              {errors.houseAddress && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.houseAddress.message}
                </p>
              )}
            </div>
            <div>
              <label className="block   font-medium text-black-700 mb-1">
                Nearest University Name
              </label>
              <input
                type="text"
                {...register("nearestUniversiyy", {
                  required: "Location is required",
                })}
                placeholder="Nearest University Name"
                className="w-full border border-gray-300 rounded-lg py-2 px-4 text-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500 "
              />
              {errors.nearestUniversiyy && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.nearestUniversiyy.message}
                </p>
              )}
            </div>
            <div>
              <label className="block   font-medium text-black-700 mb-1">
                Distance From University
              </label>
              <input
                type="text"
                {...register("distanceFromUniversity", {
                  required: "Location is required",
                })}
                placeholder="distance From University"
                className="w-full border border-gray-300 rounded-lg py-2 px-4 text-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500 "
              />
              {errors.distanceFromUniversity && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.distanceFromUniversity.message}
                </p>
              )}
            </div>
            <div>
              <label className="block font-medium text-black mb-1">
                Rent Per Month &euro;
              </label>
              <input
                type="number"
                {...register("price_per_month", {
                  required: "Price per month is required",
                })}
                placeholder="Rent per month"
                className="w-full border border-gray-300 rounded-lg py-2 px-4 text-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              />
              {errors.price_per_month && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.price_per_month.message}
                </p>
              )}
            </div>

            {/* Images */}
            {/* Multiple Image Upload */}
            <div className="sm:col-span-2">
              <label className="block font-medium text-black mb-1">
                Upload Images
              </label>
              <input
                type="file"
                {...register("images")}
                onChange={handleImageUpload}
                multiple
                className="w-full border border-gray-300 rounded-lg py-2 px-4 text-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              />

              {/* Image Previews */}
              <div className="mt-4 flex space-x-4">
                {imagePreviews.map((src, index) => (
                  <div key={index} className="relative">
                    <img
                      src={src}
                      alt={`Preview ${index}`}
                      className="h-24 w-24 object-cover rounded-md shadow-lg"
                    />
                    <button
                      onClick={() => handleRemoveImage(index)}
                      className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 hover:bg-red-700 "
                    >
                      <FaTimes className="h-4 w-4" />
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div className="sm:col-span-2 space-y-4  ">
              <h1 className="text-xl text-blue-600 font-semibold">
                Housing Images
              </h1>
              <div className="grid grid-cols-3 gap-5">
                {housingData?.images.map((data, index) => (
                  <div key={index} className="relative">
                    <img
                      src={data.image}
                      alt={`Preview ${index}`}
                      className="h-[350px] w-full object-cover rounded-md shadow-lg"
                    />
                    <button
                      onClick={() =>
                        handleDeleteImage(housingData?.id, data?.id)
                      }
                      className="absolute top-5 right-5 bg-red-500 text-white rounded-full p-1 hover:bg-red-700"
                    >
                      <FaTimes className="h-4 w-4" />
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* House Details */}

            <div className="w-full">
              <label className="block font-medium text-black mb-1">
                Rooms Available
              </label>
              <Controller
                name="rooms_available"
                control={control}
                rules={{ required: "Rooms Available is required" }}
                render={({ field }) => (
                  <DatePicker
                    selected={field.value ? new Date(field.value) : null}
                    onChange={(date) => {
                      const formattedDate = date
                        ? new Date(
                            date.getTime() - date.getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .split("T")[0]
                        : null;
                      field.onChange(formattedDate);
                    }}
                    isClearable
                    dateFormat="yyyy/MM/dd"
                    placeholderText="Select a date"
                    className="w-full border border-gray-300 rounded-lg py-2 px-4 text-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={15}
                  />
                )}
              />
              {errors["rooms_available"] && (
                <p className="text-red-500 text-sm mt-1">
                  {errors["rooms_available"].message}
                </p>
              )}
            </div>

            {/* Rooms End */}
            <div className="flex items-center gap-4">
              <div>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={isUntilChecked}
                    onChange={(e) => setIsUntilChecked(e.target.checked)}
                    className="form-checkbox"
                  />
                  <span>Until</span>
                </label>
              </div>
              <div className="w-full">
                <label className="block font-medium text-black mb-1">
                  Rooms End
                </label>
                <Controller
                  name="rooms_end"
                  control={control}
                  rules={{
                    required:
                      !isUntilChecked &&
                      "Rooms End is required when 'Until' is unchecked",
                  }}
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value ? new Date(field.value) : null}
                      onChange={(date) => {
                        const formattedDate = date
                          ? new Date(
                              date.getTime() - date.getTimezoneOffset() * 60000
                            )
                              .toISOString()
                              .split("T")[0]
                          : null;
                        field.onChange(formattedDate);
                      }}
                      isClearable
                      dateFormat="yyyy/MM/dd"
                      placeholderText="Select a date"
                      className="w-full border border-gray-300 rounded-lg py-2 px-4 text-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={15}
                      minDate={new Date()}
                      disabled={isUntilChecked}
                    />
                  )}
                />
                {errors["rooms_end"] && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors["rooms_end"].message}
                  </p>
                )}
              </div>
            </div>

            <div>
              <label className="block font-medium text-black mb-1">
                Number of Rooms
              </label>
              <input
                type="number"
                {...register("rooms", {
                  required: "Number of rooms is required",
                })}
                placeholder="No. of Rooms"
                className="w-full border border-gray-300 rounded-lg py-2 px-4 text-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              />
              {errors["rooms"] && (
                <p className="text-red-500 text-sm mt-1">
                  {errors["rooms"].message}
                </p>
              )}
            </div>

            <div>
              <label className="block font-medium text-black mb-1">
                Kitchens
              </label>
              <input
                type="number"
                {...register("kitchen", {
                  required: "Number of kitchens is required",
                })}
                placeholder="No. of Kitchens"
                className="w-full border border-gray-300 rounded-lg py-2 px-4 text-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              />
              {errors["kitchen"] && (
                <p className="text-red-500 text-sm mt-1">
                  {errors["kitchen"].message}
                </p>
              )}
            </div>

            <div>
              <label className="block font-medium text-black mb-1">
                Bathrooms
              </label>
              <input
                type="number"
                {...register("bathroom", {
                  required: "Number of bathrooms is required",
                })}
                placeholder="No. of Bathrooms"
                className="w-full border border-gray-300 rounded-lg py-2 px-4 text-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              />
              {errors["bathroom"] && (
                <p className="text-red-500 text-sm mt-1">
                  {errors["bathroom"].message}
                </p>
              )}
            </div>
            <div>
              <label className="block font-medium text-black mb-1">
                Max- Allow Person
              </label>
              <input
                type="number"
                {...register("allowed_person", {
                  required: "Number of allowed person is required",
                })}
                placeholder="No. of allowed person"
                className="w-full border border-gray-300 rounded-lg py-2 px-4 text-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              />
              {errors["allowed person"] && (
                <p className="text-red-500 text-sm mt-1">
                  {errors["allowed person"].message}
                </p>
              )}
            </div>

            {/* Floor */}
            <div>
              <label className="block font-medium text-black mb-1">Floor</label>
              <input
                type="text"
                {...register("floor", { required: "Floor is required" })}
                placeholder="Floor"
                className="w-full border border-gray-300 rounded-lg py-2 px-4 text-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              />
              {errors.floor && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.floor.message}
                </p>
              )}
            </div>

            {/* Price */}

            {/* Apartment Size */}
            <div>
              <label className="block font-medium text-black mb-1">
                Apartment Size (m&sup2;)
              </label>
              <input
                type="text"
                {...register("size_of_the_apartment", {
                  required: "Size of the apartment is required",
                })}
                placeholder="Apartment size (e.g., 71 m²)"
                className="w-full border border-gray-300 rounded-lg py-2 px-4 text-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500  "
              />
              {errors.size_of_the_apartment && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.size_of_the_apartment.message}
                </p>
              )}
            </div>

            {/* Amenities */}
            <div>
              <label className="block font-medium text-black mb-1">
                Features
              </label>
              <div>
                <Controller
                  name="features"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      inputRef={ref}
                      options={amenitiesOptions}
                      value={amenitiesOptions?.filter((option) =>
                        value?.includes(option.value)
                      )}
                      onChange={(selectedOptions) => {
                        const selectedIds = selectedOptions
                          ? selectedOptions.map((option) => option.value)
                          : [];
                        onChange(selectedIds);
                      }}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          outline: "none",
                          padding: "3px 0",
                          borderRadius: "8px",
                        }),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          color: "blue",
                          fontWeight: "bold",
                        }),
                        // placeholder: (provider) => ({
                        //   ...provider,
                        //   fontSize: "14px",
                        // }),
                        multiValue: (provided) => ({
                          ...provided,
                          fontWeight: "500",
                          padding: "1px 0",
                          background: "white",
                          border: "1px solid #ddd",
                          borderRadius: "5px",
                          marginLeft: "10px",
                        }),

                        multiValueLabel: (provided) => ({
                          ...provided,
                          opacity: "0.8",
                        }),
                      }}
                      placeholder="Select All Amenities"
                      isClearable
                      isMulti
                      noOptionsMessage={() => "No Match found"}
                    />
                  )}
                />
              </div>
              {errors.amenities && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.amenities.message}
                </p>
              )}
            </div>
            <div>
              <label className="block font-medium text-black mb-1">
                Gender
              </label>
              <div>
                <Controller
                  name="gender"
                  control={control}
                  rules={{ required: "This field is required" }}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        options={gender}
                        value={gender?.find(
                          (option) => option.value === field.value
                        )}
                        onChange={(selectedOption) => {
                          field.onChange(
                            selectedOption ? selectedOption.value : null
                          );
                        }}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            outline: "none",
                            padding: "3px 0",
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            color: "blue",
                            fontWeight: "bold",
                          }),
                          // placeholder: (provider) => ({
                          //   ...provider,
                          //   fontSize: "14px",
                          // }),
                          multiValue: (provided) => ({
                            ...provided,
                            fontWeight: "500",
                            padding: "2px 0",
                            background: "white",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                            marginLeft: "10px",
                          }),
                          multiValueLabel: (provided) => ({
                            ...provided,
                            opacity: "0.8",
                          }),
                        }}
                        placeholder="Select your housing "
                        isSearchable
                        isClearable
                        noOptionsMessage={() => "No Match found"}
                      />
                      {errors.university && (
                        <p className="text-red-500 font-medium ml-3 mt-1">
                          {errors.university.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
              {errors.gender && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.gender.message}
                </p>
              )}
            </div>

            <div>
              <label className="block font-medium text-black mb-1">
                Parking Option
              </label>
              <div>
                <Controller
                  name="parkingOption"
                  control={control}
                  rules={{ required: "Parking option is required" }}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        options={parkingOptions}
                        value={parkingOptions?.find(
                          (option) => option.value === field.value
                        )}
                        onChange={(selectedOption) => {
                          field.onChange(
                            selectedOption ? selectedOption.value : null
                          );
                        }}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            outline: "none",
                            padding: "3px 0",
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            color: "green",
                            fontWeight: "bold",
                          }),
                          multiValue: (provided) => ({
                            ...provided,
                            fontWeight: "500",
                            padding: "2px 0",
                            background: "white",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                            marginLeft: "10px",
                          }),
                          multiValueLabel: (provided) => ({
                            ...provided,
                            opacity: "0.8",
                          }),
                        }}
                        placeholder="Select parking availability"
                        isSearchable
                        isClearable
                        noOptionsMessage={() => "No options available"}
                      />
                      {errors.parkingOption && (
                        <p className="text-red-500 font-medium ml-3 mt-1">
                          {errors.parkingOption.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
            </div>

            <div>
              <label className="  font-medium text-black mb-1">
                Email for Contact <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                {...register("emailAddress", {
                  required: "Size of the apartment is required",
                })}
                placeholder="Email Address for House booking"
                className="w-full border border-gray-300 rounded-lg py-2 px-4 text-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500  "
              />
              {errors.emailAddress && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.emailAddress.message}
                </p>
              )}
            </div>
            <div>
              <label className="  font-medium text-black mb-1">
                Phone Number For Contact <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                {...register("phoneNumber", {
                  required: "Phone Number is required",
                })}
                placeholder="Phone number for House booking"
                className="w-full border border-gray-300 rounded-lg py-2 px-4 text-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500  "
              />
              {errors.phoneNumber && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.phoneNumber.message}
                </p>
              )}
            </div>
            <div>
              <label className="  font-medium text-black mb-1">
                WhatsApp Number For Contact{" "}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                {...register("whatsAppNumber", {
                  required: "Whatsapp number is required",
                })}
                placeholder="WhatsApp number for House booking"
                className="w-full border border-gray-300 rounded-lg py-2 px-4 text-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500  "
              />
              {errors.whatsAppNumber && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.whatsAppNumber.message}
                </p>
              )}
            </div>

            {/* Description */}
            {/* <div className="sm:col-span-2">
            <label className="block font-medium text-black mb-1">
              Description
            </label>
            <textarea
              {...register("description", {
                required: "Description is required",
              })}
              placeholder="Enter a brief description of the apartment"
              rows="4"
              className="w-full border border-gray-300 rounded-lg py-2 px-4 text-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            />
            {errors.description && (
              <p className="text-red-500 text-sm mt-1">
                {errors.description.message}
              </p>
            )}
          </div> */}
          </div>
          <div className=" w-full mx-auto mt-8 bg-white">
            <ReactQuill
              value={description}
              onChange={setDescription}
              style={editorStyle}
              modules={modules}
            />
          </div>

          {/* Submit Button */}
          <div className="sm:col-span-2 mt-5">
            <button
              type="submit"
              className="w-[300px] flex justify-center items-center mx-auto bg-gradient-to-r from-indigo-500 to-purple-500 text-white py-3 px-4 rounded-lg font-semibold hover:from-indigo-600 hover:to-purple-600 transition-all duration-200"
            >
              Submit Apartment
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateHousingById;
