/* eslint-disable react/prop-types */
import { FaBan } from "react-icons/fa";
import SEOData from "../SEO/SEO";

const PermissionDenied = ({ pageTitle, errorData }) => {
  return (
    <div className="bg-slate-100 w-full h-full">
      <SEOData
        title={`Dashboard || ${pageTitle}`}
        description={`You do not have the required permissions to access this resource.
          Please contact your administrator to request access.`}
        canonical={window.location.pathname}
      />
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        {/* Icon */}
        <div className="mb-6">
          <div className="w-24 h-24 bg-gray-200 rounded-full flex items-center justify-center">
            <FaBan className="text-gray-500 w-16 h-16" />
          </div>
        </div>

        {/* Main message */}
        <h1 className="text-2xl font-semibold text-gray-800 mb-2">
          Access Denied
        </h1>
        <p className="text-lg text-gray-700 mb-4">You do not have access</p>
        <p className="text-gray-600 text-center mb-8">
          You do not have the required permissions to access this resource.
          Please contact your administrator to request access.
        </p>

        {/* Summary Section */}
        <div className="w-full max-w-2xl bg-white rounded-lg p-6">
          <h2 className="text-lg font-bold text-gray-800 mb-4">Summary</h2>
          <div className="grid grid-cols-2 gap-4 text-sm">
            <div>
              <p className="text-gray-600 font-medium">Error</p>
              <p className="text-gray-800">Permission Error</p>
            </div>
            <div>
              <p className="text-gray-600 font-medium">Error code</p>
              <p className="text-gray-800">{errorData?.status}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermissionDenied;
